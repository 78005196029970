import React, { useEffect, useMemo, useState } from "react";
import styles from "../../../styles/home.module.scss";
import MainDonationsCard from "../../mainDonationCard";
import { observer } from "mobx-react";
import { sendMetrics } from "../../../utils/misc";
import BlockContainer from "../blockContainer";
import style from "./bloodStationsNeed.module.scss";
import Spinner from "../../spinner";
import CustomCarousel from "../../ui/customCarousel";
import { useStores } from "../../../hooks/mobx";

type TNeedComponent = {
  need: any;
  showOnCart: any,
  isInCart: boolean
};

export const NeedComponent: React.FC<TNeedComponent> = ({ need, showOnCart, isInCart = false }) => {
  return (
    <div
      onClick={() => {
        sendMetrics("click_bs_needs_main_page");
      }}
      className={styles.swiper_element}
    >
      <MainDonationsCard info={need} showOnCart={showOnCart} isInCart={isInCart}/>
    </div>
  );
};

const BloodStationsNeed: React.FC = observer(() => {
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 3000, min: 1920 },
      items: 4,
      slidesToSlide: 4,
    },
    desktop: {
      breakpoint: { max: 1919, min: 1440 },
      items: 3,
      slidesToSlide: 3,
    },
    laptop: {
      breakpoint: { max: 1439, min: 1200 },
      items: 3,
      slidesToSlide: 3,
    },
    tablet: {
      breakpoint: { max: 1199, min: 768 },
      items: 2,
      slidesToSlide: 2,
    },
    mobile: {
      breakpoint: { max: 767, min: 360 },
      items: 1,
      slidesToSlide: 1,
    },
  };

  const { bloodStationsNeedStore, selectedCityStore, uiStore } = useStores();

  const [needList, setNeedList] = useState([]);
  const [notFounded, setNotFounded] = useState(false);
  const [loader, setLoader] = useState(true);

  useEffect(() => {
    const filter = {
      msk: selectedCityStore.isMoscow,
      city_id: selectedCityStore.cityID,
    };
    const page_size = uiStore.isMobile ? 3 : 9;

    bloodStationsNeedStore
      .load(page_size, 1, filter)
      .then((response) => {
        setNeedList(response.records);
        setLoader(false);
        setNotFounded(
          !selectedCityStore.isMoscow ? !response.has_user_city : false
        );
      })
      .catch(() => {
        setLoader(false);
      });
  }, []);

  const content = useMemo(() => {
    let preContent;
    preContent = needList.map((need) => (
      <NeedComponent need={need} key={need.id} />
    ));

    if (notFounded)
      preContent.unshift(
        <MainDonationsCard key={"defaults"} not_founded_in_city={notFounded} />
      );

    return preContent;
  }, [notFounded, selectedCityStore?.cityID, needList]);

  if (loader)
    return (
      <div className={`${style.SpinnerBlock} container`}>
        <Spinner toggled={true} background={true} />
      </div>
    );

  if (content?.length < 3) return null;

  return (
    <BlockContainer
      title={"Потребности центров крови"}
      isLink={false}
      linkUrl={""}
      linkText={"Все потребности"}
      isGrid
      marginTop={40}
      marginTopMobile={20}
      isMobile={uiStore?.isMobile}
      isSSR={false}
    >
      <CustomCarousel
        responsive={responsive}
        removeArrowOnDeviceType={["mobile"]}
        removeDotsOnDeviceType={["mobile"]}
        swipeable={true}
        draggable={true}
        infinite={true}
        partialVisible={true}
      >
        {content}
      </CustomCarousel>
    </BlockContainer>
  );
});

export default BloodStationsNeed;
