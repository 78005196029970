import React, { useEffect, useState } from "react";
import styles from "../mainDonationCard/mainDonationCard.module.scss";
import { IBloodStationsNeed } from "../../entities/bloodStationsNeed_common";
import Link from "next/link";
import NotFoundedCard from "../mainPageBlocks/notFoundedCard/notFoundedCard";
import { observer } from "mobx-react";
import DetailBloodStationNeeds from "../detailBloodStationPageComponents/detailBloodStationNeeds";
import DetailBloodStationPlan from "../detailBloodStationPageComponents/detailBloodStationPlan";
import { useStores } from "../../hooks/mobx";
import redirectTo from "../../utils/redirectTo";
import MyButton from "../myButton";

type TMainDonationsCard = {
  info?: { data: IBloodStationsNeed };
  not_founded_in_city?: boolean;
  showOnCart?: any;
  isInCart: boolean;
};

const MainDonationsCard: React.FC<TMainDonationsCard> = observer(
  ({ info, not_founded_in_city, showOnCart, isInCart = false }) => {
    const { uiStore, citiesStore, selectedCityStore } = useStores();

    const [city, setCity] = useState({ title: null, slug: null });

    useEffect(() => {
      if (selectedCityStore?.cityData) {
        setCity({
          title: selectedCityStore.cityData?.title,
          slug: selectedCityStore.cityData?.slug,
        });
      } else {
        if (selectedCityStore?.cityID) {
          citiesStore
            .loadOne(selectedCityStore.cityID)
            .then((response) => {
              setCity({
                title: response.title,
                slug: response.slug,
              });
            })
            .catch(() => {});
        }
      }
    }, []);

    const onClickHandler = () => {
      if(!isInCart) {
        redirectTo(`/bloodstations/${info?.data.id}`)
      } else {
        showOnCart(info.data)
      }
    }

    if (not_founded_in_city)
      return (
        <div style={{ margin: "10px 0" }}>
          <NotFoundedCard
            slugLink={city?.slug}
            text={
              city?.title
                ? `У нас пока нет данных по городу ${city?.title}`
                : `У нас пока нет данных по вашему городу`
            }
            subText={"Уточните потребности у центров крови"}
          />
        </div>
      );

    if (!info?.data) return null;

    return (
        <div className={styles.mainDonationCard} onClick={() => onClickHandler()}>
          <div>
            <div className={styles.mainDonationCard_header}>
              <span className={styles.mainDonationCard_header_title}>
                {info.data.title}
              </span>
            </div>
          </div>
          <div>
            <DetailBloodStationNeeds
              noTitle
              needs={info.data.blood_group}
              data={info?.data}
            />
          </div>
          <div className={styles.MoreCartButton}>
            {
              isInCart &&
              <MyButton
                text={"Подробнее"}
                withLink={`/bloodstations/${info?.data.id}`}
                isSSR
                width={"100%"}
                containerWidth={"100%"}
                onClick={(e) => e.stopPropagation()}
              />
            }
          </div>
          <div>
            <DetailBloodStationPlan
              id={info.data.id}
              isMobile={uiStore.isMobile}
            />
          </div>
        </div>
    );
  }
);

export default MainDonationsCard;
